import axios from 'axios';
import config from "../config/app.config";
import { getUserLogin } from '../bl/user.bl';
import { AlertGeneral, AlertError, AlertConfirm } from "../util/alert.util";
import { Message } from "../config/message.config";
export default {
    namespaced: true,
    state: {
        userLogin: null,
    },
    mutations: {
        setUserLogin(state, data) {
            state.userLogin = data;
        },
        clearLocalSession(state) {
            state.userLogin = null;
            sessionStorage.clear();
        },
        setSaveLocalSession(state, data) {
            sessionStorage.setItem(config.SESSION.KEY, JSON.stringify(data));
        },
        loadUserLoginSession(state) {
            state.userLogin = getUserLogin();
        }
    },
    actions: {

        async login(context, { user, router }) {
            let res = null;
            try {
                context.commit('clearLocalSession');
                res = await axios.post(`${config.URL}/oauth/token`, user);
                const data = res.data;
                context.commit('setUserLogin', data);
                context.commit('setSaveLocalSession', data);
                router.push("/");
            } catch (error) {
                if (error.response != null)
                    throw error.response;
                else
                    throw error;
            }
            return res;
        },
        checkSession(context) {
            context.commit('loadUserLoginSession');
        },
        async logOut(context, router) {
            const result = await AlertConfirm(
                Message.LOG_OUT,
                Message.LOG_OUT_DETAIL
            );
            if (result.isConfirmed) {
                context.commit('clearLocalSession');
                router.push("/login");
            }
        }
    },
}