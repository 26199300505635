import Swal from "sweetalert2";
export const AlertGeneral = (data) => {
    Swal.fire({
        icon: data.statusHttp == 200 ? "success" : "error",
        title: data.title,
        text: data.message,
    });
}
export const AlertError = (error) => {

    const json = JSON.parse(JSON.stringify(error));
    let title = "Ocurrio un error";
    let sms = json?.message;
    if (error?.response?.data != null) {
        title = error?.response?.data?.error;
        sms = error?.response?.data?.message;
    }
    if (json.status == 401) {
        title  = json?.data?.error;
        sms = json?.data?.error_description;
    }
    if (json.status == 403) {
        title  = json?.error.error;
        sms = json?.error?.message;
    }
    Swal.fire({
        icon: "error",
        title: title,
        text: sms,
    });
}

export const AlertConfirm = (titulo, texto) => {
    return Swal.fire({
        title: titulo,
        text: texto,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
    });
}