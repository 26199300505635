<template>
  <div class="d-flex">
    <img id="img-log" :class="classImage" :src="imgBase64" alt="" v-if="imgBase64 != null" />
  </div>
</template>

<script>
import axios from "axios";
import config from "../config/app.config";
import { FileDTO } from "../model/fileDTO";
import { techlimEncode } from "../util/tool.util";
export default {
  name: "imageComponent",
  data() {
    return {
      imgBase64: null,
    };
  },
  props: {
    idImage: Number,
    classImage: String,
  },
  async beforeMount() {
    let url = `${config.URL}/filePlace/findFileData`;
    let item = new FileDTO();
    item.id = this.idImage;
    const value = techlimEncode(item);
    let data = await axios.post(url, value);
    this.imgBase64 = `data:image/jpeg;base64,${data.data.data}`;
  },
};
</script>

<style></style>
