import axios from 'axios';
import config from '../config/app.config'
import { AlertGeneral, AlertError, AlertConfirm } from "../util/alert.util";
export default {
    namespaced: true,
    state: {
        lstUsuariosAcceso: [],
        lstUsuarios: [],
        modelRegister: null,

    },
    mutations: {
        setLstUsuariosAcceso(state, data) {
            state.lstUsuariosAcceso = data;
        },
        setLstUsuarios(state, data) {
            state.lstUsuarios = data;
        },
        setModelRegister(state, data) {
            state.modelRegister = data;

        }

    },
    actions: {
        async obtenerUsuariosAcceso(context) {
            try {
                const data = await axios.get(`${config.URL}/userSystemAppUiConfig/listar`);
                context.commit('setLstUsuariosAcceso', data.data.data);
            } catch (error) {
                AlertError(error);
            }
        },
        async obtenerUsuarios(context) {
            try {
                const data = await axios.get(`${config.URL}/usuario/listar`);
                context.commit('setLstUsuarios', data.data.data);
            } catch (error) {
                AlertError(error);
            }
        }
    },
}