import { createRouter, createWebHashHistory } from 'vue-router'
import { isLoginUser } from '../bl/user.bl'

const verificarLogin = (to, from, next) => {
  const estado = isLoginUser();
  if (estado == true) {
    next()
  } else {
    //this.$route.push('/forbidden')
    return next(false);
  }
}
const verificarNoLogin = (to, from, next) => {
  const estado = isLoginUser();
  if (estado == false) {
    next()
  } else {
    //this.$route.push('/forbidden')
    return next(false);
  }
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('../views/Config.vue'),
    beforeEnter: verificarLogin,
  },
  {
    path: '/info-api',
    name: 'info-api',
    component: () => import('../views/ApiInfo.vue'),
    beforeEnter: verificarLogin,
  },
  {
    path: '/access-user',
    name: 'access-user',
    component: () => import('../views/AccessUser.vue'),
    beforeEnter: verificarLogin,
  }
  , {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    beforeEnter: verificarNoLogin,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
