
import config from "../config/app.config";
export const getUserLogin = () => {
    const data = sessionStorage.getItem(config.SESSION.KEY);
    if (data != null)
        return JSON.parse(data);
    return null;
}
export const isLoginUser = () => {
    return (getUserLogin() != null ? true : false);
}
export const getToken = () => {
    var user = getUserLogin();
    const token = user?.access_token;
    return token;
}