import { Constant } from "../config/message.config";

/**
     * 
     * @param str aplica formato a un string 
     * @param val 
     */
export const formatString = (str, ...val) => {
    try {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
    } catch (error) {
        console.log("CONSTANTS_APP.COMMONS.ERROR_FORMAT", error);
    }

    return str;
}
export const toJson = (data) => {
    return JSON.stringify(data);
}
export const techlimEncode = (item, encodeLevel = Constant.ENCODE_LEVEL) => {
    const json = toJson(item);
    let res = json;
    for (let i = 0; i < encodeLevel; i++) {
        res = encryptionDataEncode(res);
    }
    return res;
}
export const encryptionDataEncode = (data) => {
    let res = null;
    try {
        if (data != null)
            res = window.btoa(data, 'base64');
    } catch (error) {
        console.error(error, data);
    }
    return res;
}