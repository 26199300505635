import axios from 'axios';
import config from '../config/app.config'
import { AlertGeneral, AlertError, AlertConfirm } from "../util/alert.util";
import { Message } from "../config/message.config";
import { formatString } from "../util/tool.util";
export default {
    namespaced: true,
    state: {
        lstAplicationType: [],
        lstAplicationVersion: [],
        modelRegister: null,
        programs: [],
    },
    mutations: {
        setLstAplicationType(state, data) {
            state.lstAplicationType = data;
        },
        setLstAplicationVersion(state, data) {
            state.lstAplicationVersion = data;
        },
        setModelRegister(state, data) {
            state.modelRegister = data;
        },
        setPrograms(state, data) {
            state.programs = data;
        }
    },
    actions: {
        async obtenerAplicationType(context) {
            try {
                const data = await axios.get(`${config.URL}/applicationTypeSystemUi/listar`);
                context.commit('setLstAplicationType', data.data.data);
            } catch (error) {

            }
        },
        async obtenerAplicationVersion(context) {
            try {
                const data = await axios.get(`${config.URL}/applicationVersionSystemUi/listar`);
                context.commit('setLstAplicationVersion', data.data.data);
            } catch (error) {
                AlertError(error);
            }
        },
        async obtenerAplicationNotUseFile(context) {
            try {
                const data = await axios.get(`${config.URL}/configurationManagement/findFilesNotUsedSystemData`);
                let res = data?.data;
                // console.log(res);
                let total = res?.data?.total ?? 0;
                if (res.statusHttp != 200) {
                    AlertError(res);
                }
                

                    let lst = [
                        {
                            titulo: Message.PROGRAM_SQL,
                            descripcion: Message.PROGRAM_SQL_DESCRIPTION,
                            service: "createDefaultFunctionSQL",
                            icon: "far fa-file-alt",
                        },
                        {
                            titulo: Message.PROGRAM_DATA,
                            descripcion: Message.PROGRAM_DATA_DESCRIPTION,
                            service: "createDefaultDataSystem",
                            icon: "fas fa-database",
                        },
                        {
                            titulo: Message.PROGRAM_IMPUESTO,
                            descripcion: Message.PROGRAM_IMPUESTO_DESCRIPTION,
                            service: "createDefaultImpuesto",
                            icon: "fas fa-file-invoice-dollar",
                        },
                        {
                            titulo: Message.PROGRAM_DIRECCION,
                            descripcion: Message.PROGRAM_DIRECCION_DESCRIPTION,
                            service: "createDefaultDireccion",
                            icon: "fas fa-map-marker-alt",
                        },
                        {
                            titulo: Message.PROGRAM_REMOVE_FILE_NOT_USE,
                            descripcion: formatString(Message.PROGRAM_REMOVE_FILE_NOT_USE_DESCRIPTION, total),
                            service: "removeNotUseFileSystemData",
                            icon: "fas fa-file-archive",
                        },
                        {
                            titulo: Message.PROGRAM_INIT_DATA,
                            descripcion: Message.PROGRAM_INIT_DATA_DESCRIPTION,
                            service: "createOlyBaseDateSystem",
                            icon: "fa fa-solid fa-fire",
                        },
                    ];
                    context.commit('setPrograms', lst);
                
            } catch (error) {
                AlertError(error);
            }
        },
    },
}