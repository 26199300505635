export const Message = {
    TITLE_QUESTION: '¿Estas seguro?',
    TITLE_QUESTION_DELETE: '¿Estas seguro de eliminar?',
    DESCRIPCION_DELETE_VERSION: 'Al eliminar la versión ({0}) puede que los sitemas Web  y Móvil que usan esta versión no funcionen(asegúrese de que ya no este en uso esta versión), desea continuar... ',
    DESCRIPCION_DELETE_USUARIO_ACCESS: 'Se eliminará el acceso al usuario ({0}), desea continuar... ',
    DESCRIPTION_EXECUTE_SQL: 'Se ejecutara los sql en el sistema ',
    PROGRAM_SQL: 'SQL',
    PROGRAM_SQL_DESCRIPTION: 'Actualización de vistas, funciones, triggers',
    PROGRAM_DATA: 'DATOS',
    PROGRAM_DATA_DESCRIPTION: 'Creación y actualización de datos(Menu,permisos,Roles) en el sistema',
    PROGRAM_IMPUESTO: 'IMPUESTOS',
    PROGRAM_IMPUESTO_DESCRIPTION: 'Creación y actualización de impuestos en el sistema',
    PROGRAM_INIT_DATA_DESCRIPTION: 'Creación y actualización de opciones y datos basicos del sistema',
    PROGRAM_DIRECCION: 'DIRRECIÓN',
    PROGRAM_DIRECCION_DESCRIPTION: 'Creación y actualización de direcciones en el sistema',
    PROGRAM_REMOVE_FILE_NOT_USE: 'ARCHIVOS',
    PROGRAM_INIT_DATA: 'Datos Iniciales',
    PROGRAM_REMOVE_FILE_NOT_USE_DESCRIPTION: 'Eliminación de archivos que ya no estan en uso en el sistema (cache de archivos), existen un total de {0} archivos por eliminar',
    LOG_OUT: '¿Cerrar sesión?',
    LOG_OUT_DETAIL: 'Esta seguro de que desea salir...',
    TOKEN: 'Bearer'

}
export const Constant = {
    ENCODE_LEVEL: 3,
    CHANNEL_ID_APP: 1
}