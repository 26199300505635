import app from '../../package.json';

export default {
    URL: process.env.VUE_APP_URL,
    ESTADO: process.env.VUE_APP_ESTADO,
    VERSION: app.version,
    NAME: String(app.name)?.toUpperCase(),
    WARNING: app.warning,
    SESSION: {
        KEY: 'user_log'
    }
}