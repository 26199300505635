<template>
  <div id="nav">
    <LoadingComponent v-if="openLoading"></LoadingComponent>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="container-fluid">
        <router-link
          class="navbar-brand d-flex justify-content-center align-items-center"
          :to="'/'"
        >
          <i class="fab fa-codepen fa-2x me-2" aria-hidden="true"></i>
          {{ version?.data?.appNombre }}
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul
            class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
            style="--bs-scroll-height: 100px;"
          >
            <li class="nav-item">
              <router-link class="nav-link" to="/" :active-class="'active'">
                <i class="fas fa-home"></i> Inicio
              </router-link>
            </li>

            <li class="nav-item" v-if="userLogin != null">
              <router-link
                class="nav-link"
                to="/config"
                :active-class="'active'"
              >
                <i class="fas fa-cogs"></i> Configuración
              </router-link>
            </li>
            <li class="nav-item" v-if="userLogin != null">
              <router-link
                class="nav-link"
                to="/access-user"
                :active-class="'active'"
              >
                <i class="fas fa-users"></i> Acceso usuario
              </router-link>
            </li>
            <li class="nav-item" v-if="userLogin != null">
              <router-link
                class="nav-link"
                to="/info-api"
                :active-class="'active'"
              >
                <i class="fas fa-cloud-meatball"></i> API
              </router-link>
            </li>
            <li class="nav-item" v-if="userLogin == null">
              <router-link
                class="nav-link"
                to="/login"
                :active-class="'active'"
              >
                <i class="fas fa-users"></i> Login
              </router-link>
            </li>
          </ul>
          <div class="d-flex">
            <div
              v-if="userLogin != null"
              class="p-1 d-flex w-100 justify-content-between align-items-center"
            >
              <ImageComponent
                :idImage="userLogin.idFoto"
                :classImage="'perfil-logo'"
              ></ImageComponent>
              <span class="text-white p-1">
                {{ userLogin.nombreusuario }}
              </span>
              <button class="btn btn-outline-light" @click="logOutUser">
                <i class="fas fa-power-off"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<style>
.main {
  background-color: #f0f2f5;
  min-height: 90vh;
}
a.router-link-exact-active {
  color: #42b983;
}
.min-accion {
  /* max-width: 1em; */
}
.perfil-logo {
  width: 2.8em;
  height: 2.8em;
  border: 1px solid white;
  border-radius: 50px;
}
.select2-container{
  z-index: 9999;
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import config from "./config/app.config";
import LoadingComponent from "./components/Loading.vue";
import ImageComponent from "./components/Image.vue";
export default {
  name: "App",
  components: {
    LoadingComponent,
    ImageComponent,
  },

  computed: {
    ...mapState(["version", "loading"]),
    ...mapState("autentication", ["userLogin"]),
  },
  methods: {
    ...mapActions(["obtenerVersion"]),
    ...mapActions("autentication", ["checkSession", "logOut"]),
    ...mapMutations(["loadingOpen", "loadingClose"]),
    logOutUser() {
      this.logOut(this.$router);
    },
  },
  created() {
    console.log(
      `%c${config.NAME}`,
      "font-weight: bold; font-size: 50px;color: CornflowerBlue; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)"
    );
    console.log(
      `%cV${config.VERSION}`,
      "font-weight: bold; font-size: 35px;color: CornflowerBlue; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)"
    );
    console.log(
      `%c¡${config.ESTADO == "true" ? "PRODUCCIÓN" : "DESARROLLO"}!`,
      "color: rgb(0, 162, 255); font-size: 30px; font-weight: bold;"
    );
    console.log(
      "%c¡DETENTE!",
      "color: red; font-size: 30px; font-weight: bold;"
    );
    console.log(config.WARNING);
  },
  async beforeMount() {
    this.loadingOpen();
    this.checkSession();
    await this.obtenerVersion();
    this.loadingClose();
  },
  data() {
    return {
      openLoading: false,
    };
  },
  watch: {
    loading: function(newQuestion, oldQuestion) {
      this.openLoading = newQuestion;
    },
  },
};
</script>
