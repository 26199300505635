import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'mobius1-selectr/dist/selectr.min.css'
import 'mobius1-selectr/dist/selectr.min.js'
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import axios from 'axios';
import { getToken } from './bl/user.bl'
import { Message } from './config/message.config'
import VueCookies from 'vue-cookies';
axios.defaults.withCredentials = true
axios.defaults.xsrfCookieName = 'XSRF-TOKEN'
axios.defaults.xsrfHeaderName = "XSRF-TOKEN"
axios.interceptors.request.use(function (config) {
    
    const token = getToken();
    let nameCookie = 'XSRF-TOKEN'; 
    var cookie = VueCookies.get(cookie);
    var cookie = getCookie(nameCookie);
    if (cookie) {
        config.headers.common[nameCookie] = getCookie(nameCookie);;
    }
    if (token) {
        config.headers.Authorization = `${Message.TOKEN} ${token}`;
    }
    return config;

}, function (err) {
    return Promise.reject(err);
});
function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}
createApp(App).use(store).use(router).mount('#app')
