import { createStore } from 'vuex';
import AutenticationModule from '@/modules/autentication.module';
import ConfigModule from '@/modules/config.module';
import axios from 'axios';
import config from '../config/app.config'
import UserModule from '../modules/user.module';
export default createStore({
  state: {
    version: null,
    loading: null,
  },
  mutations: {
    setVersion(state, version) {
      state.version = version;
    },
    loadingClose(state) {
      state.loading = false;
    },
    loadingOpen(state) {
      state.loading = true;
    },
  },
  actions: {
    async obtenerVersion(context) {
      try {
        // console.log(config);
        const version = await axios.get(`${config.URL}/version/detalle`);
        //console.log(version.data);
        context.commit('setVersion', version.data);
      } catch (error) {

      }

    },
  },
  modules: {
    autentication: AutenticationModule,
    config: ConfigModule,
    user:UserModule
  }
})
